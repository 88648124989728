import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1258.7 1258.7"
      {...props}
    >
      <path d="M685 629.4l573.7-573.8L1203.1.1 629.3 573.7 55.6 0 0 55.6l573.7 573.8L0 1203.1l55.6 55.6L629.3 685l573.8 573.7 55.6-55.6z" />
    </svg>
  )
}

export default SvgComponent

import { createStore, applyMiddleware } from "redux";
import Reducers from "./reducers";
import thunk from 'redux-thunk';

const state = {

};

export default function initializeStore(initialState = state) {
  return createStore(
    Reducers,
    initialState,
    applyMiddleware(thunk)
  );
}

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store';
import { Provider } from 'react-redux'; 
import {
  BrowserRouter as Router, Route
} from "react-router-dom";

class Index extends Component {
  render() {
    return (
      <Provider store={store()}>
        <Router>
          <Route path="/"><App /></Route>
        </Router>
      </Provider>
    )
  }
}

ReactDOM.render(<Index />, document.getElementById('root'));
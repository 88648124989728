import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3282.2 4773.9"
      {...props}
    >
      <g>
        <path fillOpacity="0" d="M1641.201 4773.9c-905.9-1.1-1640-735.1-1641-1641V1641.1c1-905.9 735.1-1639.9 1641-1641 435.5-1.2 853.3 171.9 1160.4 480.7 308.7 307.1 481.8 724.9 480.6 1160.3v1491.8c1.2 435.4-171.9 853.2-480.6 1160.3-307.2 308.8-725 481.9-1160.4 480.7zm0-4550.1c-782.3.9-1416.4 634.9-1417.2 1417.2v1491.8c.9 782.3 634.9 1416.3 1417.2 1417.2 782.3-.9 1416.4-634.9 1417.2-1417.2V1641.1c-.9-782.4-634.9-1416.4-1417.2-1417.3z" />
        <path fillOpacity="0" d="M1641.201 4773.9c-905 0-1641.2-736.2-1641.2-1641.1V1641.1C.001 736.2 736.101 0 1641.201 0c438.4 0 850.5 170.7 1160.4 480.7 309.9 310 480.6 722.1 480.6 1160.4v1491.7c0 438.3-170.7 850.4-480.6 1160.4-309.9 310-722.1 480.7-1160.4 480.7zm0-4550.1c-781.5 0-1417.2 635.8-1417.2 1417.2v1491.7c0 781.5 635.8 1417.2 1417.2 1417.2s1417.2-635.8 1417.2-1417.2V1641.1c0-781.5-635.8-1417.3-1417.2-1417.3z" />
        <circle id="mouse_bubble_1" cx={1641.2} cy={2014} r={223.8} />
        <circle id="mouse_bubble_2" cx={1641.2} cy={1268.1} r={223.8} />
      </g>
    </svg>
  )
}

export default SvgComponent

import React from 'react';
import { Scroll } from "./";

interface TabsProps {
    ids?: string[],
    active?: string,
    changeTab: Function,
    scrollText?: string
}

export default function Tabs(props: TabsProps) {

    const { ids = [], active = "", changeTab } = props;

    const returnTabs = () => ids.map((id, key) => {
        return <div className={"button" + (active === id ? " active" : "")} 
            key={key} onClick={() => changeTab(id)}>{id}</div>
    });

    return (
        <div className="tabs">
            {returnTabs()}
            {props.scrollText && 
                <Scroll relative={true} mb="0.25rem" ml="1rem" scrollText={props.scrollText} />
            }
        </div>
    )
}

import React, { useState, useRef, useEffect } from 'react';
import { Photo, Download } from '../App';
import gsap from 'gsap';
import { Scroll } from "./";
import parse from 'html-react-parser';
import { Download as DownloadIcon } from '../icons';

interface ParallaxProps {
    elements?: Photo[],
    name?: string,
    text?: string,
    info?: string,
    type: string,
    scrollText?: string,
    downloads?: Download[]
}

let animation1: any, animation2: any, timer: any, timer1: any, timer2: any, timer3: any, timer4: any;

export default function Parallax(props: ParallaxProps) {

    const [left, setLeft] = useState(0);
    const [imgLoadCount, setImgLoadCount] = useState(1);
    const [width, setWidth] = useState(0);
    const [screen, setScreen] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [widths, setWidths] = useState<number[] | []>([]);
    const wrapper: any = useRef(null);
    const turnOffScroll = props.elements ? (props.elements.length ? false : true) : true;

    useEffect(() => {
        window.addEventListener('resize', calculatePositions);

        if (turnOffScroll) contentLoaded();
         
        return () => {
            window.removeEventListener('resize', calculatePositions);
            if (animation1) animation1.kill();
            if (animation2) animation2.kill();
            animation1 = null;
            animation2 = null;
            clearTimeout(timer);
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
        }
    }, []);

    const calculatePositions = () => {
        clearTimeout(timer1);
        timer1 = setTimeout(() => {
            if (!wrapper || !props.elements) return;
            const width = wrapper.current.clientWidth;
            const screen = window.innerWidth;
            setWidth(width);
            setScreen(screen);
            const nodes = wrapper.current.children;
            let widthsArray: number[] = [];
            for (let i = 0, j = nodes.length; i < j; i++) {
                widthsArray.push(Math.floor(nodes[i].clientWidth))
            }
            const sum = widthsArray.reduce((a: number, b: number) => a + b , 0);
            const tempMargin = Math.floor((width - sum) / (nodes.length - 1));
            widthsArray = widthsArray.map((w, i) => i >= (widthsArray.length - 1) ? w : w + tempMargin);
            setWidths(widthsArray);
        }, 300);
    }

    const contentLoaded = () => {
        calculatePositions();

        timer4 = setTimeout(() => {
            animate(0);
        });

        timer3 = setTimeout(() => {
            setLoaded(true);
        }, 250);
    }

    const handleImgLoad = (count: Number) => {
        setImgLoadCount(imgLoadCount + 1);

        if (imgLoadCount >= count) {
            contentLoaded();
        }
    }

    const returnElements = (elements?: Photo[]) => {
        if (!elements) return;
        const elementsCount = elements.length;
        const photos = elements.map(({ alt = "", src = "" }: Photo, i) => 
            <img key={i} src={src} alt={alt} onLoad={() => handleImgLoad(elementsCount)} />
        );
        if (props.type === 'gallery') return photos;
        const text_element = (
            <div key={-1} className={"content_slide" + (loaded ? " loaded" : "") + (props.type === 'home' ? " home" : "") + (props.type === 'decoration' ? " decoration" : "")}>
                {props.scrollText && 
                    <Scroll relative={true} mb="6rem" scrollText={props.scrollText} />
                }
                <h1>{props.name || ""}</h1>
                {props.info && 
                    <div className="info">{props.info || ""}</div>
                }
                <div className="text_holder">
                    {parse(props.text || "")}
                </div>
                {returnButtons(props.downloads)}
            </div>
        );
        return [text_element, ...photos];
    }

    const returnButtons = (downloads?: Download[]) => {
        if (!downloads || !downloads.length) return;
        const list = downloads.map(({name, link}: Download, i) => (
            <div key={i}>
                <a href={link || ''} className="button" download>
                    {name || ''}
                    <DownloadIcon />
                </a>
                <div className="clear"></div>
            </div>
        ));
        return <div className="buttons">{list}</div>
    }

    const mouseWheelEvt = (e: any) => {
        if (turnOffScroll) return;
        const newLeft = left + e.deltaY;  
        animate(newLeft);
    }

    const animate = (newLeft: number) => {
        let limitedLeft = newLeft;
        setLeft(newLeft);
        let slidesSum = 0;
        let activeSlide = 0;
        let percentage = 0;

        for (let i = 0, j = widths.length; i < j; i++) {
            slidesSum += widths[i];
            if (newLeft < slidesSum - screen * 0.88 || newLeft === 0) {                
                percentage = (1 - (slidesSum - screen * 0.88 - newLeft) / widths[i]) * 0.35;
                activeSlide = i;
                break;
            }
        }

        const slideNode = wrapper.current.children[activeSlide];
        const prevNode = wrapper.current.children[activeSlide - 1];
        percentage = percentage + 0.65;
        if (percentage > 1) percentage = 1;

        if (animation1) animation1.kill();
        
        clearTimeout(timer);
        
        animation1 = gsap.to(wrapper.current, {
            x: (limitedLeft * -1),
            duration: 1.85,
            ease: "power4.out"
        });

        if (slideNode) {
            gsap.to(slideNode, {
                scale: percentage,
                duration: 1.85,
                ease: "power4.out"
            });
        }

        if (prevNode) {
            gsap.to(wrapper.current.children[activeSlide - 1], {
                scale: 1,
                duration: 1.85,
                ease: "power4.out"
            });
        } else {
            gsap.to(slideNode, {
                scale: 1,
                duration: 1.85,
                ease: "power4.out"
            });
        }
        
        if (newLeft <= 0) {
            limitedLeft = 0;
            setLeft(0);
        } else if (width - screen <= newLeft) {
            limitedLeft = width - screen;
            setLeft(width - screen);
        }
        if (limitedLeft !== newLeft) {
            timer = setTimeout(() => {
                if (animation2) animation2.kill();
                if (animation1) animation1.kill();
                animation2 = gsap.to(wrapper.current, {
                    x: (limitedLeft * -1),
                    duration: 0.3,
                    ease: "power1.out"
                });
            }, 300);
        }
    }
    
    return (
        <>
            <div className="parallax_container" onWheel={(e) => mouseWheelEvt(e)}>
                {!loaded && 
                    <div className="loader_container">
                        <div className="loader"></div>
                    </div>
                }
                <div className={"parallax_wrapper"  + (loaded ? ' loaded' : '')} ref={wrapper}>
                    {returnElements(props.elements)}
                </div>
            </div>
        </>
    )
}

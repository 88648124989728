import React, { Component } from 'react';
import { withRouter } from "react-router";
import {RouteComponentProps} from "react-router";

type PathParamsType = {
    history?: any
}

type ListenerProps = RouteComponentProps<PathParamsType> & {
    children?: JSX.Element | JSX.Element[],
    pathChanged: Function
}

class Listener extends Component<ListenerProps> {
    unlisten: any = null;

    componentDidMount() {
      this.unlisten = this.props.history.listen(({ pathname = "" }: any, action: any) => {
        this.props.pathChanged(pathname, action);
      });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
       return (
           <div>{this.props.children}</div>
        );
    }
  }

  export default withRouter(Listener);

import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1479.6 1479"
      {...props}
    >
      <path d="M1341 1109.2v231.1H138.7v-231.1H0V1479h1479.6v-369.8zM1195.8 653.7l-98.3-98.3-288.2 288.3V0H670.6v843.4L382.1 554.9l-98.3 98.2 455.7 456.1z" />
    </svg>
  )
}

export default SvgComponent

import React, { useState } from 'react';
import { GalleryTab } from "../App";
import { Tabs, Scroll, Parallax } from "../components";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';

interface GalleryInterface {
    data?: GalleryTab[],
    scrollText?: string,
    headHtml?: string
}

export default function Gallery(props: GalleryInterface) {

    const returnTabsId = (data?: GalleryTab[]) => {
        if (!data) return;
        return data.map(({name = ""}: GalleryTab) => name);
    }
    
    const setDefaultTab = () => {
        const tabIds = returnTabsId(props.data);
        if (tabIds) {
            if (tabIds[0]) return tabIds[0];
        }
        return '';
    }

    const [activeTab, setActiveTab] = useState(setDefaultTab());
    const [fade, setFade] = useState(false);
    const [showParallax, setShowParallax] = useState(true);

    const returnTabs = (data?: GalleryTab[]) => {
        if (!data) return;
        const activeTabData: GalleryTab[] = data.filter(({ name = "" }: GalleryTab) => activeTab === name);
        if (!activeTabData.length) return;
        return showParallax ? <Parallax elements={activeTabData[0].photos} type="gallery" /> : null;
    }

    const changeTab = (id: string) => {
        setFade(true);
        setTimeout(() => {
            setShowParallax(false);
            setTimeout(() => {
                setActiveTab(id);
                setShowParallax(true);
                setFade(false);
            });
        }, 410);
    }

    const returnHead = (headData?: string) => {
        if (!headData) return;
        try {
            const html = parse(headData);
            return html;
        } catch(err) {}
    }
    
    return (
        <div className="gallery_pop">
            <Helmet>{returnHead(props.headHtml)}</Helmet>
            <Tabs ids={returnTabsId(props.data || [])} active={activeTab} changeTab={(id: string) => changeTab(id)} />
            <Scroll l="3.6rem" t="50%" mt="-1.6rem" scrollText={props.scrollText} />
            <div className={"tabs_holder" + (fade ? " fade" : "")}>{returnTabs(props.data)}</div>
        </div>
    )
}

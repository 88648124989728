import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect 
} from "react-router-dom";
import { 
  Gallery,
  House,
  Decoration,
  News,
  NotFound,
  Found
} from './pages';
import { Close } from "./icons";
import {
  fetchData
} from "./store/actions";
import RouteListener from "./RouteListener";
import homeParallax from "./homeParallax";

let prefix = "";
try {
  const node = document.getElementById('json');
  if (node) {
    const temp = JSON.parse(node.innerHTML);
    prefix = temp.prefix;
  }
} catch(err) {
  console.log(err);
}

declare global {
  interface Window {
    goToPage: any;
  }
}

export interface Download {
  name?: string,
  link?: string
}

export interface HouseInterface {
  info: string,
  text: string,
  name: string,
  photos: Photo[],
  head_html?: string,
  downloads?: Download[]
}

export interface DecorationTab {
  name: string,
  text: string,
  photos: Photo[]
}

export interface Photo {
  src: string,
  alt: string
}

export interface ContentElment {
  type: string,
  content?: string,
  id?: string,
  src?: string,
  alt?: string
}

export interface NewsInterface {
  name: string,
  contents: ContentElment[],
  head_html?: string
}

export interface GalleryTab {
  name: string,
  photos: Photo[]
}

interface AppProps {
  dispatch: Function,
  close_text?: string,
  scroll_text?: string,
  houses?: {[key: string]: HouseInterface},
  news?: {[key: string]: NewsInterface},
  gallery?: GalleryTab[],
  decoration?: DecorationTab[],
  index_page_head_html?: string,
  gallery_page_head_html?: string,
  decoration_page_head_html?: string,
  gallery_page_path?: string,
  decoration_page_path?: string
}

interface AppState {
  redirect: string | null,
  opened: boolean
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.props.dispatch(fetchData());

    this.state = {
      redirect: null,
      opened: false
    }
  }

  componentDidMount() {
    window.goToPage = (url: string) => {
      if (url.length > 2) {
        if (url === 'gallery' && this.props.gallery_page_path) {
          url = this.props.gallery_page_path;
        } else if (url === 'decoration' && this.props.decoration_page_path) {
          url = this.props.decoration_page_path;
        }
        url = url.toLocaleLowerCase();
        this.setState({redirect: prefix + '/' + url, opened: true}, () => {
          const html = document.getElementsByTagName('html')[0];
          html.classList.add('opened_pop');
          this.setState({redirect: null});
        });
      } else {
        this.closePop();
      }
    }
    const pathname = window.location.pathname.substring(prefix.length + 1);
    if (pathname.length > 2) {
      setTimeout(() => {
        window.goToPage(pathname);
      }, 400);
    }
    setTimeout(() => {
      homeParallax();
    }, 1000);
  }

  goToPage(path: string, action: any) {
    console.log();
    if (path.length - prefix.length > 2) {
      window.goToPage(path.substring(prefix.length + 1));
    } else {
      this.closePop();
    }
  }

  returnNews() {
    const news = this.props.news;
    if (!news) return;
    return Object.keys(news).map(key => {
      return (
        <Route path={`${prefix}/${key.toLowerCase()}`} exact key={key}>
          <News data={news[key]} scrollText={this.props.scroll_text} />
        </Route>
      )
    });
  }

  returnHouses() {
    const houses = this.props.houses;
    if (!houses) return;
    return Object.keys(houses).map(key => {
      return (
        <Route path={`${prefix}/${key.toLowerCase()}`} exact key={key}>
          <House data={houses[key]} scrollText={this.props.scroll_text} />
        </Route>
      )
    });
  }

  closePop() {
    this.setState({ opened: false }, () => {
       setTimeout(() => {
        this.setState({ redirect: prefix }, () => {
          const html = document.getElementsByTagName('html')[0];
          html.classList.remove('opened_pop');
          this.setState({redirect: null});
        });
       }, 1001);
    });
  }

  render() {
    if (!this.props.close_text) return <div></div>;
    
    return (
      <div className={"overlay" + (this.state.opened ? " open" : "")}>

        {this.state.redirect !== null ? (
          <Redirect push to={this.state.redirect} />
        ) : (
          <>
            <Router>
              <RouteListener pathChanged={(path: string, action: any) => this.goToPage(path, action)}>
                <Switch>
                  {this.returnHouses()}
                  {this.returnNews()}
                  <Route path={`${prefix}/${this.props.gallery_page_path || "gallery"}`} exact>
                    <Gallery 
                      data={this.props.gallery} 
                      scrollText={this.props.scroll_text} 
                      headHtml={this.props.gallery_page_head_html}
                    />
                  </Route>
                  <Route path={`${prefix}/${this.props.decoration_page_path || "decoration"}`} exact>
                    <Decoration 
                      data={this.props.decoration} 
                      scrollText={this.props.scroll_text} 
                      headHtml={this.props.decoration_page_head_html}
                    />
                  </Route>
                  <Route path={prefix} exact><Found headHtml={this.props.index_page_head_html}/></Route>
                  <Route><NotFound /></Route>
                </Switch>
              </RouteListener>
            </Router>
            <div className="close_button" onClick={() => this.closePop()}>
              <div className="text">{this.props.close_text}</div>  
              <Close />
            </div>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any): { store: AppProps | {} } => state.store || {}

const connectedApp = connect(mapStateToProps)(App);

export default (process.env.NODE_ENV === 'development'
  ? hot(module)(connectedApp)
  : connectedApp);
import React, { CSSProperties } from 'react';
import { Mouse } from "../icons";

interface ScrollProps {
    t?: string,
    l?: string,
    r?: string,
    b?: string,
    mt?: string,
    ml?: string,
    scrollText?: string,
    relative?: boolean,
    mb?: string
}

export default function Scroll(props: ScrollProps) {

    const { t, l, r, b, mt, ml, scrollText = "", mb, relative } = props;

    const styles: CSSProperties = {
        top: t || 'auto',
        bottom: b || 'auto',
        left: l || 'auto',
        right: r || 'auto',
        marginTop: mt || 'auto',
        position: relative ? 'relative' : 'absolute',
        marginBottom: mb || 'auto',
        marginLeft: ml || '0'
    }

    return (
        <div className="scroll_element" style={styles}>
            <Mouse />
            {scrollText}
        </div>
    )
}
